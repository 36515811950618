var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p-6" },
    [
      _c("vx-card", [
        _c(
          "div",
          {
            staticClass:
              "bg-gray-100 bg-white shadow-md sticky top-0 z-10 pb-5",
          },
          [
            _vm.dataList.length > 0
              ? _c("vs-divider", {}, [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("classroom.monitoring_panel")) +
                        " - " +
                        _vm._s(_vm.classroomConfig.name) +
                        " "
                    ),
                  ]),
                ])
              : _vm._e(),
            !_vm.isFullscreen
              ? _c("div", { staticClass: "vx-row m-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full flex justify-end mb-2" },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "ml-3",
                          attrs: { icon: "monitor" },
                          on: { click: _vm.modeScreenfull },
                        },
                        [_vm._v(" Modo quiosque ")]
                      ),
                      _c(
                        "vs-button",
                        {
                          staticClass: "ml-3",
                          attrs: { type: "border" },
                          on: { click: _vm.goBack },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("common.back")) + " ")]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass:
                  "grid grid-cols-4 justify-items-center flex w-full m-2",
              },
              [
                _c(
                  "div",
                  { staticClass: "flex justify-center" },
                  [
                    _c(
                      "vs-chip",
                      {
                        staticClass: "text-base text-center",
                        attrs: { transparent: "", color: "success" },
                      },
                      [_vm._v("Aguardando avaliação")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "flex justify-center" },
                  [
                    _c(
                      "vs-chip",
                      {
                        staticClass: "text-base text-center",
                        attrs: { transparent: "", color: "warning" },
                      },
                      [_vm._v("Em avaliação")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "flex justify-center" },
                  [
                    _c(
                      "vs-chip",
                      {
                        staticClass: "text-base text-center",
                        attrs: { transparent: "", color: "primary" },
                      },
                      [_vm._v("Finalizado")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "flex justify-center" },
                  [
                    _c(
                      "vs-chip",
                      {
                        staticClass: "text-base text-center",
                        attrs: { transparent: "", color: "danger" },
                      },
                      [_vm._v("Solicitado suporte")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "overflow-auto h-[calc(3*16rem)] mt-4" }, [
          _c(
            "div",
            {
              staticClass:
                "grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-10 2xl:grid-cols-10 gap-2 overflow-y-auto",
              staticStyle: { "max-height": "calc(3 * 13rem)" },
            },
            _vm._l(_vm.dataList, function (data, index) {
              return _c(
                "div",
                { key: index },
                [
                  _c(
                    "vx-card",
                    {
                      staticClass:
                        "w-auto shadow-drop border-solid border-grey border playful",
                      attrs: {
                        headerStyle:
                          "w-full flex flex-wrap justify-center items-center font-bold text-white pb-4",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pt-2 flex items-center justify-between",
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "_class-card-title" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._.get(data, "name_identifier")
                                          )
                                        ),
                                      ]
                                    ),
                                    false
                                      ? _c("feather-icon", {
                                          staticClass: "cursor-pointer",
                                          attrs: {
                                            svgClasses:
                                              "w-5 h-5 2xl:w-4 2xl:h-4",
                                            icon: "MoreVerticalIcon",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "footer",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "footer-slot w-full flex flex-wrap justify-center items-center",
                                    class: _vm.statusColorClass(data),
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid grid-cols-6 items-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-1 text-left flex items-center pl-1",
                                          },
                                          [
                                            _vm.hasSupport(data)
                                              ? _c("font-awesome-icon", {
                                                  staticClass:
                                                    "animate-ping h-3 w-3 m-auto",
                                                  attrs: {
                                                    icon: "exclamation-triangle",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-4 flex justify-center items-center",
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "text-sm text-center font-bold",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getLabelFooter(data)
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-1 flex justify-end items-center pr-1",
                                          },
                                          [
                                            _vm.hasSupport(data)
                                              ? _c("feather-icon", {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip.top",
                                                      value: {
                                                        content:
                                                          "Detalhar pedido de suporte",
                                                        delay: {
                                                          show: 1000,
                                                          hide: 100,
                                                        },
                                                      },
                                                      expression:
                                                        "{content: 'Detalhar pedido de suporte',delay: { show: 1000, hide: 100 }}",
                                                      modifiers: { top: true },
                                                    },
                                                  ],
                                                  attrs: {
                                                    icon: "EyeIcon",
                                                    svgClasses:
                                                      "h-5 w-5 stroke-current justify-end cursor-pointer",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openSupportPopup(
                                                        data
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-col w-full gap-1 justify-center",
                        },
                        [
                          data.classroom_config.show_teacher
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "_class-card-description grid grid-cols-1",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "truncate" },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "chalkboard-teacher" },
                                      }),
                                      _vm._v(
                                        "   " +
                                          _vm._s(
                                            _vm._.get(
                                              data,
                                              "last_teacher.teacher.name",
                                              "---"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c("vs-divider", {}, [
                            _vm._v(_vm._s(_vm.getLabelDivider(data))),
                          ]),
                          data.classroom_config.show_student
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "_class-card-description grid grid-cols-1",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "truncate" },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "graduation-cap" },
                                      }),
                                      _vm._v(
                                        "   " +
                                          _vm._s(
                                            _vm._.get(
                                              data,
                                              "last_student.student.name",
                                              "---"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
        ]),
        _c(
          "div",
          { staticClass: "w-full flex items-center justify-center gap-2 mt-2" },
          [
            _c("logo", {
              staticStyle: { "max-width": "60px" },
              attrs: { alt: _vm.$t("educatena") },
            }),
          ],
          1
        ),
      ]),
      _c(
        "vs-popup",
        {
          ref: "vspopup-support",
          attrs: {
            title: _vm.supportPopupTitle,
            active: _vm.classroomSupportPopupActive,
            id: "popup-support",
          },
          on: {
            "update:active": function ($event) {
              _vm.classroomSupportPopupActive = $event
            },
            close: _vm.closePopupSupport,
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c("p", { staticClass: "_class-card-description" }, [
                _c(
                  "span",
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "chalkboard-teacher" },
                    }),
                    _vm._v(
                      "   " +
                        _vm._s(
                          _vm._.get(
                            _vm.classroomSupport,
                            "last_teacher.teacher.name",
                            "---"
                          )
                        ) +
                        " "
                    ),
                  ],
                  1
                ),
              ]),
              _c("p", { staticClass: "_class-card-description" }, [
                _c("span", [
                  _vm._v(
                    " " + _vm._s(_vm.classroomSupport.support_description) + " "
                  ),
                ]),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "flex flex-row justify-end gap-2 mt-4" },
            [
              _c(
                "vs-button",
                {
                  attrs: { color: "success" },
                  on: {
                    click: function ($event) {
                      return _vm.handlingClassroomSupport(_vm.classroomSupport)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("action.done")) + " ")]
              ),
              _c(
                "vs-button",
                {
                  staticClass: "ml-3",
                  attrs: { type: "border", color: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.classroomSupportPopupActive = false
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("action.pending")) + " ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }