<template>
  <div class="p-6">
     <vx-card>
      <div class="bg-gray-100 bg-white shadow-md sticky top-0 z-10 pb-5">
        <vs-divider class="" v-if="dataList.length > 0">
          <span >
            {{$t('classroom.monitoring_panel')}} - {{ classroomConfig.name }}
          </span>
        </vs-divider>
        <div class="vx-row m-2" v-if="!isFullscreen">
          <div class="vx-col w-full flex justify-end mb-2">
              <vs-button
                class="ml-3"
                icon="monitor"

                @click="modeScreenfull">
                  Modo quiosque
              </vs-button>
              <vs-button
                class="ml-3"
                type="border"
                @click="goBack">
                {{ $t('common.back') }}
              </vs-button>
          </div>
        </div>
        <div class="grid grid-cols-4 justify-items-center flex w-full m-2">
          <div class="flex justify-center">
            <vs-chip transparent color="success" class="text-base text-center">Aguardando avaliação</vs-chip>
          </div>
          <div class="flex justify-center">
            <vs-chip transparent color="warning" class="text-base text-center">Em avaliação</vs-chip>
          </div>
          <div class="flex justify-center">
            <vs-chip transparent color="primary" class="text-base text-center">Finalizado</vs-chip>
          </div>
          <div class="flex justify-center">
            <vs-chip transparent color="danger" class="text-base text-center">Solicitado suporte</vs-chip>
          </div>
        </div>
      </div>
      <div class="overflow-auto h-[calc(3*16rem)] mt-4">
        <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-10 2xl:grid-cols-10 gap-2 overflow-y-auto"
        style="max-height: calc(3 * 13rem);"
        >

      <!--<div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-3">-->
        <div :key="index" v-for="(data, index) in dataList" class="">
          <vx-card
            class="w-auto shadow-drop border-solid border-grey border playful"
            headerStyle="w-full flex flex-wrap justify-center items-center font-bold text-white pb-4">
            <template v-slot:header>
              <div class="pt-2 flex items-center justify-between">
                <label class="_class-card-title">{{ _.get(data, 'name_identifier')}}</label>
                <feather-icon
                  v-if="false"
                  svgClasses="w-5 h-5 2xl:w-4 2xl:h-4"
                  class="cursor-pointer"
                  icon="MoreVerticalIcon"/>
              </div>
            </template>
            <div class="flex flex-col w-full gap-1 justify-center">
              <p class="_class-card-description grid grid-cols-1" v-if="data.classroom_config.show_teacher">
                  <!--<span :key="indext" v-for="(dTeacher, indext) in data.teachers">-->
                <span class="truncate">
                  <font-awesome-icon icon="chalkboard-teacher"/> &nbsp; {{ _.get(data,'last_teacher.teacher.name', '---') }}
                </span>
              </p>
              <vs-divider class="">{{ getLabelDivider(data) }}</vs-divider>
              <p class="_class-card-description grid grid-cols-1" v-if="data.classroom_config.show_student">
                <!--<span :key="indexs" v-for="(dStudent, indexs) in data.students">-->
                <span class="truncate">
                  <font-awesome-icon icon="graduation-cap"/> &nbsp; {{ _.get(data,'last_student.student.name', '---') }}
                </span>
              </p>
            </div>
            <template v-slot:footer>
              <div class="footer-slot w-full flex flex-wrap justify-center items-center" :class="statusColorClass(data)">
                <div class="grid grid-cols-6 items-center">
                  <div class="col-span-1 text-left flex items-center pl-1">
                    <font-awesome-icon icon="exclamation-triangle"
                            class="animate-ping h-3 w-3 m-auto"
                            v-if="hasSupport(data)"/>
                  </div>
                  <div class="col-span-4 flex justify-center items-center">
                    <label class="text-sm text-center font-bold">
                      {{ getLabelFooter(data) }}
                    </label>
                  </div>
                  <div class="col-span-1 flex justify-end items-center pr-1">
                    <feather-icon
                      icon="EyeIcon"
                      svgClasses="h-5 w-5 stroke-current justify-end cursor-pointer"
                      @click="openSupportPopup(data)"
                      v-if="hasSupport(data)"
                      v-tooltip.top="{content: 'Detalhar pedido de suporte',delay: { show: 1000, hide: 100 }}"/>
                  </div>
                </div>
              </div>
            </template>
          </vx-card>
        </div>
      </div>
      </div>

      <div class="w-full flex items-center justify-center gap-2 mt-2">
        <logo :alt="$t('educatena')" style="max-width: 60px;"/>
      </div>
    </vx-card>
    <vs-popup :title="supportPopupTitle" :active.sync="classroomSupportPopupActive" id="popup-support" @close="closePopupSupport" ref="vspopup-support">
      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="_class-card-description">
            <span>
              <font-awesome-icon icon="chalkboard-teacher"/> &nbsp; {{ _.get(classroomSupport,'last_teacher.teacher.name', '---') }}
            </span>
          </p>
          <p class="_class-card-description">
            <span>
              {{ classroomSupport.support_description }}
            </span>
          </p>
        </div>
      </div>
      <div class="flex flex-row justify-end gap-2 mt-4">
        <vs-button
          color="success"
          @click="handlingClassroomSupport(classroomSupport)">
          {{ $t('action.done') }}
        </vs-button>
        <vs-button
          class="ml-3"
          type="border"
          color="danger"
          @click="classroomSupportPopupActive = false">
          {{ $t('action.pending') }}
        </vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>

import screenfull from 'screenfull'

import ClassroomConfigService from '@/services/api/ClassroomConfigService'
import ClassroomService from '@/services/api/ClassroomService'

import Logo from '@/layouts/components/Logo.vue'

export default {
  components: {
    Logo,
  },
  props: {
    id: {
      default: null,
      type: Number
    }
  },
  data: () => ({
    classroomConfig: null,
    dataList: [],
    isFullscreen: false,
    classroomSupport: {
      name_identifier: null,
      name_identifier: null
    },
    classroomSupportPopupActive: false,
    //Service
    service: null,
    classroomService: null
  }),
  computed: {
    noDataText(){
     return this.$t('nenhum-registro-encontrado')
    },
    supportPopupTitle(){
      return 'Suporte - '+(this.classroomSupport ? this.classroomSupport.name_identifier : '[Nenhuma sala configurada]')
    },
    isLargeScreen(){
      return window.innerWidth >= 1580; // Valor padrão para `2xl` no Tailwind
    }
  },
  methods: {
     goBack() {
      this.$router.push('/classrooms')
    },
    loadConfig(){
      this.service.read({id: this.id}).then(data => {
        this.classroomConfig = data
      })
    },
    loadData() {
      this.$vs.loading()
      this.service.getClassroomToPanel(this.id).then(response => {
        this.dataList = response
      },
      error => {
        this.showErrors(this.$validator, error)
        this.notifyError(this.$vs, this.$t('nao-foi-possivel-realizar-esta-operacao')
        )
      }
      ).finally(() => { this.$vs.loading.close() })
    },
    modeScreenfull(){
      screenfull.request(document.documentElement, {
          navigationUI: 'hide'
        })
      screenfull.on('change', () => {
        this.isFullscreen = screenfull.isFullscreen
        //console.log('Am I fullscreen?', screenfull.isFullscreen ? 'Yes' : 'No');
      })
    },
    modeScreenfullExit(){
      screenfull.exit()
    },
    getLabelDivider(data){
      //const hasSupport = _.get(data,'has_support',false)
      //if(hasSupport){
      //  return 'Solicitado suporte'
      //}else{
        if(!data.last_teacher && !data.last_student){
          return 'Sala vazia'
        } else if(!data.last_teacher){
          return 'Sala sem professor'
        }else if(!data.last_student){
          return 'Sala sem aluno'
        }else{
          return 'Sala em avaliação'
        }
      //}
    },
    getLabelFooter(data){
      const hasSupport = this.hasSupport(data)
      if(hasSupport){
        return 'Solicitado suporte'
      }else{
        if((data.last_teacher && data.last_teacher.id) && (data.last_student && data.last_student.id)){
          return 'Em avaliação'
        }else{
          return 'Aguardando avaliação'
        }
        //return data.last_student && data.last_student.id ? 'Em avaliação' :  'Aguardando avaliação'
      }
    },
    statusColorClass(data){
      const hasSupport = this.hasSupport(data)
      /*
      let currentElement = this.$el;
      const footerDiv = currentElement.closest('.vx-card')?.querySelector('.vx-card__footer');
      if (footerDiv) {
        if(hasSupport){
          footerDiv.classList.add('classroom-has-support')
        }else if(data.last_student && data.last_student.id != 0){
          footerDiv.classList.add('test-started')
        }else{
          footerDiv.classList.add('test-not-started')
        }
      }
      */

      if(hasSupport){
        return 'classroom-has-support'
      }else{
        return data.last_student && data.last_student.id != 0 ? 'test-started' :  'test-not-started'
      }

    },
    hasSupport(data){
      const hasSupport = _.get(data,'has_support',false)
      return hasSupport
    },
    openSupportPopup(data){
      this.classroomSupportPopupActive = true
      this.classroomSupport = data

      /*
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Suporte - '+data.name_identifier,
        acceptText: this.$t('action.done'),
        cancelText: this.$t('action.pending'),
        text: data.support_description,
        accept: () => {this.handlingClassroomSupport(data)}
      })
      */
    },
    handlingClassroomSupport(data){
      this.$vs.loading()

      const payload = {}
      const classroomId = data.id

      this.classroomService.suppportRequestDone(classroomId, payload).then(
        response => {
          data.has_support = false
          this.classroomSupportPopupActive = false
        },
        error => {
        }
        ).finally(() => {this.$vs.loading.close()}
      )
    }
  },
  mounted() {
    this.loadData()
    this.loadConfig()
    this.interval = setInterval(() => {
      if (this.dataList.length > 0) {
        this.loadData()
      }
    }, 5000)
  },
  beforeMount() {
    this.service = ClassroomConfigService.build(this.$vs)
    this.classroomService = ClassroomService.build(this.$vs)
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }
}
</script>

<style>
  ._class-card-title, ._class-card-description {
    @apply overflow-hidden;
    @apply text-justify;
    @apply break-words;
  }
  ._class-card-title {
    min-height: 1.5rem;
    max-height: 1.5rem;
  }
  ._class-card-description {
    min-height: 2rem;
    max-height: 5rem;
  }
</style>

<style lang="scss" scoped>

.playful /deep/ .vx-card__header {
  padding-top: 0.5rem;
  //background: padding-box linear-gradient(218deg,#00eef6,#00dde6 50%,#31acff);
  background: padding-box  linear-gradient(218deg, rgba(var(--vs-primary), 1), rgba(var(--vs-primary), .7)) !important;
  border-radius: 0.4rem;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.playful /deep/ .vx-card__footer {
  border-top: 1px solid #b8c2cc80;
  padding: 0.25rem 0.50rem 0.50rem 0.50rem;
}

.playful /deep/ .vx-card__body {
  padding: 1rem;
}
</style>

<style lang="scss">
  .test-not-started {
    background: rgba(var(--vs-success),.15);
    color: rgba(var(--vs-success),1);
  }

  .test-started {
    background: rgba(var(--vs-warning),.15);
    color: rgba(var(--vs-warning),1);
  }

  .test-finished {
    background: rgba(var(--vs-primary),.15);
    color: rgba(var(--vs-primary),1);
  }
  .classroom-has-support {
    background: rgba(var(--vs-danger),.15);
    color: rgba(var(--vs-danger),1);
  }
</style>
